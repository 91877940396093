import styled from '@emotion/styled'

import { breakpoints } from '../../utils/styles'

export const Price = styled.h3`
  font-size: 3rem;
  margin: 0.5em 0;
  font-weight: normal;
`

export const Chevron = styled.span`
  width: 10px;
  height: 10px;
  display: block;
  background-color: red;
`

export const CartButtons = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  > li {
    &:first-of-type {
      width: calc(100% - 10rem);
    }
    &:last-child {
      width: 8rem;
    }
  }
`

export const BuyButton = styled.button`
  background: #525252;
  border: 1px solid white;
  text-transform: uppercase;
  color: white;
  padding: 1rem 4rem 1rem 1rem;
  font-size: 2rem;
  display: block;
  width: 100%;
  text-align: left;
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
  cursor: pointer;
  svg {
    position: absolute;
    right: 1rem;
  }
  &:hover {
    color: black;
    background-color: white;
  }
`

export const WishButton = styled.button`
  background: #525252;
  border: 1px solid white;
  text-transform: uppercase;
  color: white;
  padding: 1rem;
  font-size: 2rem;
  width: 100%;
  cursor: pointer;
  &:hover {
    color: black;
    background-color: white;
  }
`

export const selectStyle = {
  menu: (provided, state) => ({
    ...provided,
    // width: state.selectProps.width,
    border: '1px solid white',
    color: 'white',
    padding: 0,
    backgroundColor: '#525252',
    borderRadius: 0,
  }),

  control: () => ({
    // width: width,
    backgroundColor: 'transparent',
    color: 'white',
    border: '1px solid white',
    padding: '1rem',
    height: '4.8rem',
    cursor: 'pointer',
  }),

  singleValue: () => ({
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '2rem',
    height: '2em',
  }),

  valueContainer: () => ({
    padding: 0,
  }),

  placeholder: () => ({
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '2rem',
  }),

  indicatorsContainer: () => ({
    //display: 'none',
    position: 'absolute',
    right: '0.2rem',
    top: '0.5rem',
  }),

  option: (provided, state) => ({
    backgroundColor: state.isFocused ? 'white' : 'transparent',
    color: state.isFocused ? '#525252' : 'white',
    padding: '1rem',
    fontSize: '2rem',
    textTransform: 'uppercase',
    cursor: 'pointer',
  }),

  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1
  //   const transition = 'opacity 300ms'
  //   return { ...provided, opacity, transition }
  // },
}
