import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

export const ProductWrap = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    display: flex;
  }
`

export const ProductImage = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    width: 60%;
    display: flex;
  }
`

export const ProductImageMain = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    width: 80%;
    order: 2;
    padding: 0 2rem;
  }
`
export const ProductImageThumbs = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    width: 20%;
  }
`

export const ProductContent = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    width: 40%;
    order: 2;
  }
`

export const ProductTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
`

export const ProductDescription = styled.div`
  margin-bottom: 4rem;
  font-size: 1.6rem;
`

export const ImgWrap = styled.div`
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  padding: 1rem;
`
