import React, { useState } from 'react'
import { graphql } from 'gatsby'

import SEO from '~/components/seo'
import ProductForm from '~/components/ProductForm'
import { Img, Container } from '~/utils/styles'
import {
  ImgWrap,
  ProductWrap,
  ProductImage,
  ProductContent,
  ProductTitle,
  ProductDescription,
  ProductImageMain,
  ProductImageThumbs,
} from './styles'

const Product = ({ data }) => {
  const product = data.shopifyProduct

  const [activePhoto, setActivePhoto] = useState(0)

  return (
    <>
      <SEO title={product.title} description={product.description} />
      <Container>
        <ProductWrap>
          <ProductContent>
            <ProductTitle>{product.title}</ProductTitle>
            {product.descriptionHtml && (
              <ProductDescription
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
            )}
            <ProductForm product={product} />
          </ProductContent>
          {product.images.length > 0 && (
            <ProductImage>
              <ProductImageMain>
                <img src={product.images[activePhoto].originalSrc} alt="" />
                {/* <Img
                  src={product.images[activePhoto].originalSrc}
                  // fluid={ product.images[activePhoto].localFile.childImageSharp.fluid}
                  alt={product.title}
                /> */}
              </ProductImageMain>
              <ProductImageThumbs>
                {product.images.map((image, i) => (
                  <ImgWrap
                    key={image.id}
                    onClick={() => setActivePhoto(i)}
                    style={{
                      backgroundColor:
                        i === activePhoto
                          ? 'rgba(255, 255, 255, 0.1)'
                          : 'transparent',
                    }}
                  >
                    <img src={image.originalSrc} alt="" />
                    {/* <Img
                      src={image.originalSrc}
                      // fluid={image.localFile.childImageSharp.fluid}
                      alt={product.title}
                    /> */}
                  </ImgWrap>
                ))}
              </ProductImageThumbs>
            </ProductImage>
          )}
        </ProductWrap>
      </Container>
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
      }
    }
  }
`

export default Product
