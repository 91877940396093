import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  Component,
} from 'react'

import Select, { components } from 'react-select'

import { ToastContainer, Slide, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Cart, Heart } from 'emotion-icons/boxicons-regular'

import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

import StoreContext from '~/context/StoreContext'

import {
  Price,
  CartButtons,
  BuyButton,
  Chevron,
  WishButton,
  selectStyle,
} from './styles'

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Chevron />
    </components.DropdownIndicator>
  )
}

const ProductForm = ({ product }) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRange: { minVariantPrice },
  } = product

  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)
  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  const [available, setAvailable] = useState(true) // useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          variant => variant.id === productVariant.shopifyId
        )
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [client.product, productVariant.shopifyId, variants]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value)
  }

  const handleOptionChange = event => {
    // optionIndex, { target }

    const { value, index: optionIndex } = event
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    )

    setVariant({ ...selectedVariant })
  }

  const handleAddToCart = () => {
    addVariantToCart(productVariant.shopifyId, quantity)
    toast.configure()
    toast.dark('Product added to cart', {
      position: 'bottom-right',
      autoClose: 3000,
      transition: Slide,
    })
  }

  const handleWishlist = () => {
    const currentWishlist =
      JSON.parse(localStorage.getItem('offormWishlist')) || []

    if (!currentWishlist.some(e => e.handle === product.handle)) {
      const wishlistRow = {
        'name': product.title,
        'handle': product.handle,
      }

      currentWishlist.push(wishlistRow)
      localStorage.setItem('offormWishlist', JSON.stringify(currentWishlist))
    }

    toast.configure()
    toast.dark('Product added to wishlist', {
      position: 'bottom-right',
      autoClose: 3000,
      transition: Slide,
    })
  }

  /* 
  Using this in conjunction with a select input for variants 
  can cause a bug where the buy button is disabled, this 
  happens when only one variant is available and it's not the
  first one in the dropdown list. I didn't feel like putting 
  in time to fix this since its an edge case and most people
  wouldn't want to use dropdown styled selector anyways - 
  at least if the have a sense for good design lol.
  */
  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    })
    if (match === undefined) return true
    // if (match.availableForSale === true) return false
    return false
  }

  const price = Intl.NumberFormat(undefined, {
    currency: minVariantPrice.currencyCode,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(variant.price)

  return (
    <>
      <Price>{price}</Price>
      <p style={{ marginBottom: '1rem' }}>FORMAT TYPES</p>
      {options.map(({ id, name, values }, index) => {
        const valuesMapped = values.map(value => {
          const valueObject = {
            value: value,
            label: value,
            key: `${name}-${value}`,
            index,
            // disabled: true,
          }
          return valueObject
        })
        return (
          <React.Fragment key={id}>
            {/* <label htmlFor={name}>{name} </label> */}

            <Select
              options={valuesMapped}
              name={id}
              key={id}
              onChange={event => handleOptionChange(event)}
              // components={{ DropdownIndicator }}
              placeholder="Select format"
              styles={selectStyle}
              isSearchable={false}
              defaultValue={valuesMapped[0]}
            />
            {/* <select
              name={name}
              key={id}
              onChange={event => handleOptionChange(index, event)}
            >
              {values.map(value => (
                <option
                  value={value}
                  key={`${name}-${value}`}
                  disabled={checkDisabled(name, value)}
                >
                  {value}
                </option>
              ))}
            </select> */}
            <br />
          </React.Fragment>
        )
      })}
      {/* <label htmlFor="quantity">Quantity </label>
      <input
        type="number"
        id="quantity"
        name="quantity"
        min="1"
        step="1"
        onChange={handleQuantityChange}
        value={quantity}
      /> */}
      <CartButtons>
        <li>
          <BuyButton
            type="submit"
            disabled={!available || adding}
            onClick={handleAddToCart}
          >
            Add to Cart
            <Cart />
          </BuyButton>
        </li>
        <li>
          <WishButton onClick={handleWishlist}>
            <Heart />
          </WishButton>
        </li>
      </CartButtons>
      {!available && <p>This Product is out of Stock!</p>}
    </>
  )
}

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        // availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductForm
